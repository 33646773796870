<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.异常列表')"
                 fixed
                 right-:text="$t('tbm.确认')"
                 @click-left="back"
                 @click-right="submitForm">
    </van-nav-bar>
    <div style="padding-top:46px;background: #fff;">
      <van-checkbox-group v-model="checkedList">
        <div v-for="(item,index) in unusualList"
             :key="index"
             v-show="unusualList.length > 0"
             class="unusualClass">
          <van-checkbox shape="square"
                        :name="index"
                        v-model="item.checked"
                        @click="checkboxClick(item,index)"
                        ref='checkbox'
                        style="margin-right:10px;width:30px;"></van-checkbox>
          <div>
            <div v-for="(items,key) in dataList"
                 :key="key">
              <label>{{items.label}}</label>
              <p v-if="items.text == 'workDay'">{{item[items.text].substring(0, 10)}}</p>
              <p v-else-if="items.text == 'signFlag'">{{findTextMet(item[items.text],signFlagList,item)}}</p>
              <p v-else-if="items.text == 'dataFlag'"
                 style="color:rgb(240, 45, 45);">{{getunusualName(item[items.text])}}</p>
              <p v-else>{{item[items.text]}}</p>
            </div>
          </div>
        </div>
        <div style="background:#eee;display:flex;"
             v-show="unusualList.length == 0">
          <img src="@/views/business/images/empty.png"
               alt=""
               style="width:200px;height:150px;margin:20px auto;">
        </div>
      </van-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      unusualList: [],
      workDay: '',
      dataList: [
        {
          label: '日期',
          text: 'workDay'
        },
        {
          label: '区间',
          text: 'rangeName'
        },
        {
          label: '打卡时间',
          text: 'signTime'
        },
        {
          label: '上下班标记',
          text: 'signFlag'
        },
        {
          label: '异常类型',
          text: 'dataFlag'
        },
      ],
      signFlagList: [
        {
          value: '0',
          text: '签到'
        }, {
          value: '1',
          text: '签退'
        }
      ],
      unusualTypeList: [
        { value: '05', text: '设备故障' },
        { value: '06', text: '交通管制' },
        { value: '07', text: '交通故障' },
        { value: '00', text: '忘记打卡' },
      ],
      checkedList: []
    }
  },
  methods: {
    checkboxClick (item, index) {
      item.checked = !item.checked;
      if (item.checked) {
        this.checkedList.push(index);
      } else {
        let flag = this.checkedList.indexOf(index);
        if (flag != -1) {
          this.checkedList.splice(flag, 1);
        }
      }
      console.log(this.checkedList, item, index)
    },
    getunusualName (dataFlag) {
      let name = '';
      if (dataFlag === '1') {
        name = '迟到'
      } else if (dataFlag === '2') {
        name = '早退'
      } else if (dataFlag === '3') {
        name = '缺勤'
      } else {
        name = '未签'
      }
      return name;
    },
    getUnusualList () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/getUnusualList',
        params: {
          'workDay': this.workDay
        }
      }).then(res => {
        var backData = res.data.ext;
        if (backData && backData.length > 0) {
          this.unusualList = backData
          this.unusualList.map((item,index) => {
            let flag = this.$route.params.form.applyUnusualList.findIndex(items => {
              return item.workDay.startsWith(items.dateDetail) && item.signFlag === items.signFlag
            })
            if(flag !== -1) {
              item.checked = true;
              this.checkedList.push(index);
            } else  {
              item.checked = false;
            }
          })
        }
      });
    },
    findTextMet (value, data, items) {
      if (!value || !data || data.length == 0) {
        return value;
      } else {
        return data.find(item => item.value == value).text;
      }
    },
    back () {
      this.$router.replace({
        name: 'tbm/applyUnusual/applyUnusualEdit',
        query: {
          ...this.$route.query
        },
        params: {
          ...this.$route.params,
        }
      })
    },
    submitForm () {
      let form = this.$route.params.form;
      var applyUnusualList = [];
      this.checkedList.map((item,index) => {
        let objData = this.unusualList[item];
        applyUnusualList.push({
          pkUser: form.pkUser,
          pkDept: form.pkDept,
          pkOrg: form.pkOrg,
          signFlag: objData.signFlag,
          dateDetail: objData.workDay,
          userName: JSON.parse(localStorage.getItem('userInfo')).cname,
          deptName: JSON.parse(localStorage.getItem('userInfo')).deptName,
          unusualType: '05',
          pkWorkhourRange: objData.rangeId,
          pkWorkhour: objData.pkWorkhour,
          workTimes: [],
        })
        let obj = []
        obj['value'] = objData.rangeId
        obj['label'] = objData.rangeName + '(' + objData.startTime + '-' + objData.endTime + ')'
        applyUnusualList[index].workTimes.push(obj)
      })
      this.$router.replace({
        name: 'tbm/applyUnusual/applyUnusualEdit',
        query: {
          ...this.$route.query
        },
        params: {
          ...this.$route.params,
          applyUnusualList
        }
      })
    },
  },
  created () {
    let year = (new Date().getFullYear());
    // 待修改
    //  let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
    let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth());
    this.workDay = year + '-' + month;
    this.getUnusualList();
  }
}
</script>
<style scoped>
.unusualClass {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.unusualClass .van-checkbox {
  margin: 0 10px;
}
.unusualClass > div:nth-child(2) {
  flex: 1;
  background: rgb(238, 241, 246);
  padding: 10px;
  line-height: 40px;
}
.unusualClass > div:nth-child(2) > div {
  display: flex;
}
.unusualClass > div:nth-child(2) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 80px;
}
</style>

